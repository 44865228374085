import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "../src/translations/en.json"
import translationRU from "../src/translations/ru.json"

const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
}

i18n
.use(initReactI18next)
.init({
    resources,
    fallbackLng: 'en',
    returnEmptyString: false,
    // debug: true,
    lng: localStorage.getItem('locale'),
    interpolation: {
      escapeValue: false
    },
})

export default i18n
