import { useSelector } from "react-redux"
import { daysUntil, formatDate, formatDateTime } from "../functions"

import { t } from 'i18next'

export const Account = () => {

    const statData = useSelector((state) => state.stat)

    return (
        <>
            <h1>{t('cloak.account.account')}</h1>
            <div className="account_expires">
                <div className="account_expires_content">
                    {t('cloak.account.balance')} <div className="account_expires_content_when">{statData.balance}</div>
                </div>
                <div className="account_expires_content">
                    {t('cloak.account.clicksleft')} <div className="account_expires_content_when">{statData.clicks}</div>
                </div>
                <div className="account_expires_content">
                    {t('cloak.account.expiresat')} <div className="account_expires_content_when">{formatDate(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    {t('cloak.account.daysleft')} <div className="account_expires_content_when">{daysUntil(statData.expires)}</div>
                </div>
                <div className="account_expires_content">
                    {t('cloak.account.paycloak')} <div className="account_expires_content_when"><a href="https://t.me/yuliia_m_knk" target="_blank" rel="noopener noreferrer">@yuliia_m_knk</a></div>
                </div>
            </div>

            <p>Email: {statData.email}</p>
            <p>{t('cloak.account.registered')}: {formatDateTime(statData.registered)}</p>

            {statData.payments.length > 0
            &&
            <>
                <h2>{t('cloak.account.payments')}</h2>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{t('cloak.account.summ')}, $</th>
                            <th>{t('cloak.account.perioddays')}</th>
                            <th>{t('cloak.account.when')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {statData.payments.map((e,i) =>
                            <tr key={`paymentstr${i}`}>
                                <td>{e.summ}</td>
                                <td>{e.term}</td>
                                <td>{e.whenwas}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </>
            }
        </>
    )
}